import React from 'react'
import { graphql } from 'gatsby'


import Layout from '../components/Layout'
import GenerateurPageTemplate from '../components/GenerateurPageTemplate'

// Export Template for use in CMS preview


const GenerateurPageDiscord = ({ data: { page, gen } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <GenerateurPageTemplate {...page.frontmatter} body={page.html} pseudo={gen.edges} />
    
  </Layout>
)

export default GenerateurPageDiscord

export const pageQuery = graphql`
  query GenerateurPageDiscord($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        pseudoSource
        whatPseudo
      }
    }
    gen: allDiscordCsv {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`
