import React from 'react'
import GenerateurHero from '../components/GenerateurHero'
import GenerateurGen from '../components/GenerateurGen'
import GenerateurListPseudo from './GenerateurListPseudo';
import GenerateurListPseudoBox from './GenerateurListPseudoBox';
import SimpleContent from './SimpleContent';

export default ({
  body,
  title,
  subtitle,
  whatPseudo,
  featuredImage,
  pseudoSource,
  pseudo
}) => (
  <main>
    <GenerateurHero
      title={title}
      subtitle={subtitle}
    />
    <GenerateurGen pseudos={pseudo}/>
    <SimpleContent source={body}/>
    <GenerateurListPseudo title={`Idées de pseudo pour ${ whatPseudo }`}>
      {pseudo.map((element) => (
        <GenerateurListPseudoBox pseudo={element.node.name} key={element.node.id}/>
      ))}
    </GenerateurListPseudo>

  </main>
)